import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../../assets/static/img/w-hanson-logo-white.png";
import Menu from "../../elements/menu/Menu";
import SearchHeader from "./modules/SearchHeader";
import { Button, Modal, notification } from "antd";
import Wlogo from "../../../assets/static/img/w-logo.jpg"
import axios from "axios";
import config from "../../../config";

const HeaderMarketPlace = () => {
  const menuData = {
    menuPrimary: {
      menu_1: [
        {
          text: "Softwood",
          url: "/Softwood",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          megaContent: [
            {
              megaItems: [
                {
                  text: "Cheshire Mouldings",
                  url: "/Cheshire-Mouldings",
                },
                {
                  text: "Firrings /Arris/ Featheredge /Fencing",
                  url: "/Firrings-Arris-Featheredge-Fencing",
                },
                {
                  text: "Thermowood",
                  url: "/Thermowood",
                },

                {
                  text: "C24 Softwood Treated - PEFC",
                  url: "/C24-softwood-treated-PEFC",
                },
                {
                  text: "Home Grown Eased Edge KD Treated",
                  url: "/home-grown-eased-edge-kd-treated",
                },
                {
                  text: "CLS",
                  url: "/CLS"
                },
              ]
            },
            {
              megaItems: [


                {
                  text: "PAR Contract Softwood",
                  url: "/PAR-Contract-Softwood"
                },
                {
                  text: "PAR European Softwood",
                  url: "/PAR-European-Softwood"
                },
                {
                  text: "PAR European Softwood Mouldings",
                  url: "/PAR-European-Softwood-Mouldings"
                },
                {
                  text: "Pointed Pegs",
                  url: "/pointed-pegs"
                },
                {
                  text: "Sash Materials",
                  url: "/sash-materials"
                },
                {
                  text: "Shrinkwrapped Door Linings",
                  url: "/shrinkwrapped-door-linings"
                }
              ]
            },
          ],
        },
        {
          text: "MDF Mouldings",
          url: "/MDF-Mouldings",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "MDF Skirting & Archtrave",
              url: "/MDF-Skirting-Archtrave",
            },
            {
              text: "MDF Window Boards",
              url: "/MDF-Window-Boards",
            },
            {
              text: "MDF Door Stop & Misc",
              url: "/MDF-Door-Stop-Misc",
            },
            // {
            //   text: "MDF Linings",
            //   url: "/",
            // },
            {
              text: "MDF Linings sets",
              url: "/MDF-Linings-sets",
            },
          ],
        },
        {
          text: "Insulation",
          url: "/Insulation",
          extraClass: "menu-item-has-children insulation",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Celotex",
              url: "/Celotex",
            },
            {
              text: "Fibreglass",
              url: "/Fibreglass",
            },
            {
              text: "Cavity Insulation",
              url: "/Cavity-Insulation",
            },
            {
              text: "Polystyrene",
              url: "/Polystyrene",
            },
          ],
        },
        {
          text: "Sheet Material",
          url: "/Sheet-Material",
          current: "shop",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          megaContent: [
            {
              megaItems: [
                {
                  text: "Birch Plywood",
                  url: "/Birch-Plywood",
                },
                {
                  text: "Hardwood Throughout Plywood",
                  url: "/Hardwood-Throughout-Plywood",
                },
                {
                  text: "Hardwood Plywood- FSC",
                  url: "/Hardwood-Plywood-FSC",
                },
                {
                  text: "Shuttering Plywood",
                  url: "/Shuttering-Plywood",
                },
                {
                  text: "Structural Plywood",
                  url: "/Structural-Plywood",
                },
                {
                  text: "Marine Plywood",
                  url: "/Marine-Plywood",
                },
              ],
            },
            {
              megaItems: [
                {
                  text: "OSB",
                  url: "/OSB",
                },
                {
                  text: "MDF",
                  url: "/MDF",
                },
                {
                  text: "Moisture Resistant MDF",
                  url: "/Moisture-Resistant-MDF",
                },
                {
                  text: "Chipboard",
                  url: "/Chipboard",
                },
                {
                  text: "Hardboard",
                  url: "/Hardboard",
                },
                // {
                //   text: "Decorative MDF",
                //   url: "/Decorative-MDF",
                // },
                {
                  text: "Hardie Backer",
                  url: "/Hardie-Backer",
                },
              ],
            },
            {
              megaItems: [

                // {
                //   text: "Versafire",
                //   url: "/Versafire",
                // },
                {
                  text: "Iron On Edging",
                  url: "/Iron-On-Edging",
                },
                {
                  text: "Melamine Faced MDF",
                  url: "/Melamine-Faced-MDF",
                },
                {
                  text: "Flexcell",
                  url: "/Flexcell",
                },
                {
                  text: "Correx/ Protection",
                  url: "/Correx-Protection",
                },
              ],
            },
          ],
        },
        {
          text: "Glues Sealants & Chemicals",
          url: "/Glues-Sealants-Chemicals",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "CT1",
              url: "/CT1",
            },
            {
              text: "Evo - Stik",
              url: "/Evo-Stik",
            },
            {
              text: "Bond It",
              url: "/Bond-It",
            },
            {
              text: "OB1",
              url: "/OB1",
            },
          ],
        },
        {
          text: "Metalwork",
          url: "/Metalwork",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Joist Hangers",
              url: "/Joist-Hangers",
            },
            {
              text: "Jiffy Hangers",
              url: "/Jiffy-Hangers",
            },
            {
              text: "Beading",
              url: "/Beading",
            },
            {
              text: "Ties",
              url: "/Ties",
            },
            {
              text: "Restraint Straps",
              url: "/Restraint-Straps",
            },
            {
              text: "Reinforcement Mesh",
              url: "/Reinforcement-Mesh",
            },
            {
              text: "Metalwork Misc",
              url: "/Metalwork-Misc",
            },
          ],
        },
        {
          text: "Aggregates & Heavyside",
          url: "/Aggregates-Heavyside",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Bulk Bags",
              url: "/Bulk-Bags",
            },
            {
              text: "Mini Bags",
              url: "/Mini-Bags",
            },
            {
              text: "Cement",
              url: "/Cement",
            },
            {
              text: "Top Soil",
              url: "/Top-Soil",
            },
            {
              text: "Air Bricks",
              url: "/Air-Bricks",
            },
            {
              text: "Aco Channel",
              url: "/Aco-Channel",
            },
            {
              text: "Bricks",
              url: "/bricks",
            },
            {
              text: "Concrete Blocks",
              url: "/concrete-blocks",
            },
            {
              text: "Lightweight Blocks ",
              url: "/lightweight-blocks",
            },

          ],
        },
        {
          text: "Fixings",
          url: "/Fixings",
          current: "shop",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          megaContent: [
            {
              megaItems: [
                {
                  text: "TIMco Firmahold",
                  url: "/TIMco-Firmahold",
                },
                {
                  text: "TIMco Nails",
                  url: "/TIMco-Nails",
                },
                {
                  text: "TIMco Screws",
                  url: "/TIMco-Screws",
                },
                {
                  text: "TIMco Shield Protective Film",
                  url: "/TIMco-Shield-Protective-Film",
                },
                {
                  text: "TIMco Cutting",
                  url: "/TIMco-Cutting",
                },
                {
                  text: "TIMco Drilling and Driver Bits",
                  url: "/TIMco-Drilling-and-Driver-Bits",
                },
              ],
            },
            {
              megaItems: [
                {
                  text: "TIMco Misc",
                  url: "/TIMco-Misc",
                },
                {
                  text: "Timco Bags",
                  url: "/Timco-Bags",
                },
                {
                  text: "Timco Coach Bolts",
                  url: "/Timco-Coach-Bolts",
                },
                {
                  text: "Samac Screws",
                  url: "/Samac-Screws",
                },
                {
                  text: "Drywall Screws",
                  url: "/Drywall-Screws",
                },
                {
                  text: "Studding",
                  url: "/Studding",
                },
              ],
            },
            {
              megaItems: [
                // {
                //   text: "Loose Nails",
                //   url: "/Loose-Nails",
                // },
                {
                  text: "Plasterboard/ Rawlplug Fixings",
                  url: "/Plasterboard-Rawlplug-Fixings",
                },
                {
                  text: "Masonry Nails",
                  url: "/Masonry-Nails",
                },
                {
                  text: "Misc Fixings & Ironmongery",
                  url: "/Misc-Fixings-Ironmongery",
                },
              ],
            },
          ],
        },
        {
          text: "Drywall Products",
          url: "/Drywall-Products",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            // {
            //   text: "Metal Stud & Track/ Suspended Ceiling",
            //   url: "/",
            // },
            {
              text: "Plasterboard",
              url: "/Plasterboard",
            },
            {
              text: "Plaster",
              url: "/Plaster",
            },
          ],
        },
        {
          text: "Concrete Products",
          url: "/Concrete-Products",
          extraClass: "menu-item-has-children has-mega-menu",
          subClass: "sub-menu",
          mega: "true",
          subMenu: [
            {
              text: "Pre-Stressed Lintels",
              url: "/Pre-Stressed-Lintels",
            },
            {
              text: "Copings",
              url: "/Copings",
            },
            {
              text: "Pier Caps",
              url: "/Pier-Caps",
            },
            {
              text: "Padstones",
              url: "/Padstones",
            },
            {
              text: "Concrete Misc",
              url: "/Concrete-Misc",
            },
          ],
        },
      ],
    },
  };

  const [stickyClass, setStickyClass] = useState("");

  // sticky Navbar
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 200 ? setStickyClass("b-head") : setStickyClass("");
    }
  };

  window.addEventListener("scroll", stickNavbar);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.addEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("auth_id");
    localStorage.removeItem("auth_name");
    localStorage.clear()
    notification.open({
      message: (
        <div className="d-flex">
          <div className="pr-20">
            <i
              className="fa fa-exclamation-circle text-danger"
              style={{ fontSize: "20px" }}
            ></i>
          </div>
          <div>Thank You !</div>
        </div>
      ),
      description: "You Account has been logout Successful",
    });
    navigate("/");
  };

  const [open, setOpen] = useState(false);

  const items = JSON.parse(localStorage.getItem('items'))
  const totalQty = items?.flat().reduce((total, item) => {
    return total + (parseFloat(item.price) * item.qty);
  }, 0);


  // Logout After Some time

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 600000); // 10000ms = 10secs. You can change the time.
  };
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    // localStorage.removeItem("auth_user")
    // navigate("/login")
  };


  useEffect(() => {
    if (all_category?.length == 0) {
      onAllCategory()
    }
  }, [])
  const [all_category, setAllCategory] = useState([])
  const onAllCategory = async (e) => {
    try {
      await axios({
        method: "get",
        url: `${config.apiUrl}/categories`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((res) => {
        setAllCategory(res.data)
        // const idsToFilter = [1, 5, 3, 6, 20, 12, 14, 18, 19, 7];
        // const filteredData = res.data.filter(item => idsToFilter.includes(item.id));

        // const outputss = idsToFilter.map(id => {
        //   const foundItem = filteredData.find(item => item.id === id);

        //   if (!foundItem) return null;

        //   const isMega = foundItem.sucategories.length > 6;

        //   if (isMega) {
        //     const megaContent = [];
        //     for (let i = 0; i < foundItem.sucategories.length; i += 6) {
        //       megaContent.push({ megaItems: foundItem.sucategories.slice(i, i + 6) });
        //     }
        //     return { ...foundItem, megaContent, mega: true };
        //   } else {
        //     return { ...foundItem, subMenu: foundItem.sucategories, mega: false };
        //   }
        // }).filter(item => item !== null);

      });
    } catch (error) {
      console.log(error);
    }
  };


  return (
    <div>
      <header
        className={`header header--standard header--market-place-1 ${stickyClass}`}
      >
        <div className="header__content bg-light-blue">
          <div className="container">
            <div className="header__content-left">
              <Link to="/" className="ps-logo">
                <img src={Logo} alt="whanson" width="130" />
              </Link>

              <div className="menu--product-categories">
                <div className="menu__toggle">
                  <i className="icon-menu"></i>
                  <span> Shop by Department</span>
                </div>
                <div className="menu__content">
                  <Menu
                    source={menuData.product_categories}
                    className="menu--dropdown"
                  />
                </div>
              </div>
            </div>
            <div className="header__content-center">
              <SearchHeader all_category={all_category} />
            </div>
            <div className="header__content-right">
              <div className="header__actions">
                <div className="ps-cart--mini">
                  <a className="header__extra">
                    <i className="icon-bag2 color-white"></i>
                    <span className=" bg-blue">
                      <i className="color-white">{items?.length > 0 ? items?.length : 0}</i>
                    </span>
                  </a>
                  <div className="ps-cart__content">
                    {items?.length > 0 ? (
                      <>
                        {items?.map((item, i) => {
                          return (
                            <div className="ps-cart__items p-4" key={i}>
                              <div className="ps-product--cart-mobile mb-0">
                                <div className="ps-product__thumbnail text-center">
                                  <Link to={`/product/${item.slug}`}>
                                    <img
                                      src={item.thumbnail_image != null ? item.thumbnail_image : Wlogo}
                                      alt="No Img"
                                      height='75'
                                    />
                                  </Link>
                                </div>
                                <div className="ps-product__content">
                                  <Link className="ps-product__title text-black" to={`/product/${item.slug}`} style={{ color: "black" }} >
                                    {item.name}
                                  </Link>
                                  {item.type == 'Linear' ? (
                                    <p className="varition-size"> H : {item.height}m , W : {item.width}m , L : {item.length}m </p>
                                  ) : null}
                                  <p>
                                    <strong>£{item.price} x {item.qty}</strong>
                                  </p>{" "}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        <div className="ps-cart__footer">
                          <h3 className="">
                            Sub Total:
                            <strong className="color-blue">£{parseFloat(totalQty).toFixed(2)}</strong>
                          </h3>
                          <figure className="justify-content-center">
                            <Link
                              to="/shopping-cart"
                              className="btn-ligth-blue w-50 text-center mr-10"
                            >
                              View Cart
                            </Link>
                            {!localStorage.getItem("auth_id") &&
                              !localStorage.getItem("auth_name") ? null : (
                              <a
                                className="btn-blue text-center w-50 ml-10"
                                onClick={() => setOpen(true)}
                              >
                                Checkout
                              </a>
                            )}
                          </figure>
                        </div>
                      </>
                    ) : (
                      <div className="ps-cart__items p-4" >
                        <p className="mb-0">No products in cart</p>
                      </div>
                    )}

                  </div>
                </div>
                <div className="ps-block--user-header align-items-center">
                  {!localStorage.getItem("auth_id") &&
                    !localStorage.getItem("auth_name") ? (
                    <>
                      <div className="ps-block__left">
                        <i className="icon-user"></i>
                      </div>
                      <div className="ps-block__right">
                        <Link to="/users/login" className="color-blue">
                          Login
                        </Link>
                        <Link
                          to="/users/registration"
                          className="color-blue"
                        >
                          Register
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ps-block--user-account d-flex align-items-center">
                        <i className="icon-user"></i>
                        <p
                          className="color-white mb-0 "
                          style={{ fontWeight: "500", paddingLeft: "8px", fontSize: "15px" }}
                        >
                          {localStorage.getItem("auth_name")?.split(" ")[0]}
                        </p>
                        <div className="ps-block__content bd-head-drop">
                          <ul className="ps-list--arrow">
                            {localStorage.getItem("auth_type") != "guest" ? (
                              <li className="ps-block__footer border-top-none mb-20">
                                <Link to="/profile" >My Account</Link>
                              </li>
                            ) : null}
                            <li className="ps-block__footer border-top-none">
                              <a onClick={() => handleLogout()}>Logout</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav className="navigation bg-blue">
          <div className="container" style={{ maxWidth: "1300px" }}>
            <div className="navigation__right align-items-center">
              <Menu source={menuData?.menuPrimary?.menu_1} className={"menu "} />
            </div>
          </div>
        </nav>
      </header>

      <Modal
        open={open}
        title="Rules of Refund"
        centered
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={<div className="footer-none"></div>}
      >
        <ol className="pl-20  mb-30">
          <li className="mb-10">
            Products marked as "non-returnable" on the product detail page
            cannot be returned.
          </li>
          <li className="mb-10">
            No additional information is required to return an eligible order
            unless otherwise noted in the category-specific policy.
          </li>
          <li className="mb-10">
            Products may be eligible for replacement only if the same seller has
            the exact same item in stock.
          </li>
          <li className="mb-10">
            If the replacement request is placed and the seller does not have
            the exact same product in stock, a refund would be issued to you.
          </li>
        </ol>
        <div className="text-center mb-20 ">
          <a
            className="btn-ligth-blue p-4 mr-10"
            onClick={() => setOpen(false)}
          >
            Back to Shopping
          </a>
          <Link to="/checkout" className="btn-blue p-4 ml-10">
          Proceed to Checkout
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default HeaderMarketPlace;
