import React, { useEffect } from "react"

const routes = [
  
  { path: "/", changefreq: "2024-05-23T05:37:34+00:00", priority: 1 },
  {
    path: "/users/login",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/users/forgot-password",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/users/otp-verification",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/users/registration",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/shopping-cart",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/checkout", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  {
    path: "/terms-and-conditions",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/return-policy",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/privacy-policy",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/chain-of-custody",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/slavery-human-trafficking",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/bespoke-machine-service",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/contact-us",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/about-us", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  { path: "/search/", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  { path: "/profile", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  {
    path: "/users/guest-checkout",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/career", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  {
    path: "/spot-deals",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/spot-deals-category",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/offers", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  { path: "/faq", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  {
    path: "/product-news",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/stock-clearance",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  {
    path: "/environmental-statement",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/services", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  {
    path: "/product/:slug",
    changefreq: "2024-05-23T05:37:34+00:00",
    priority: 0.8,
  },
  { path: "/:slug", changefreq: "2024-05-23T05:37:34+00:00", priority: 0.8 },
  // Add more routes here
]

function generateSitemap(routes) {
  const xmlHeader = '<?xml version="1.0" encoding="UTF-8"?>'
  const urlSetOpen =
    '<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">'
  const urlSetClose = "</urlset>"

  const urlsXml = routes
    .map(
      ({ path, changefreq, priority }) => `
        <url>
          <loc>https://w-hanson.co.uk${path}</loc>
          <lastmod>${changefreq}</lastmod>
          <priority>${priority}</priority>
        </url>
      `
    )
    .join("")

  return `${xmlHeader}${urlSetOpen}${urlsXml}${urlSetClose}`
}

function downloadSitemap(content, filename) {
  const blob = new Blob([content], { type: "application/xml" })
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const SitemapGenerator = () => {
  useEffect(() => {
    const sitemapContent = generateSitemap(routes)
    downloadSitemap(sitemapContent, "sitemap.xml")
  }, [])

  return null // This component doesn't render anything
}

export default SitemapGenerator
